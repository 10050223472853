.project-card {
  height: 287px;
  border: 1px solid #e6e6e6 !important;
  /* box-shadow: 0px 3px 1px #9e9e9e !important; */
  box-shadow: 0px 8px 8px rgba(203, 203, 203, 0.25);
  border-radius: 10px !important;
  background-color: #ffffff !important;
  z-index: 2;
  padding:  10px 10px 0 !important;
}
.bottom-80{
  bottom: 180px !important;
  position: absolute !important;
  top: 95px;
  left: 65px;
  z-index: 1 !important;
  width: 81%;
}
.cardBody{
  padding: 5px 10px !important;
}
.pointer{
  position: absolute;
  right: -20px;
  bottom: -20px;
}


.project-title {
  font-size: 14.2px;
  line-height: 21.3px;
  font-weight: 600;
}

.project-amount {
  color: #0084ff;
  font-size: 16px;
  font-weight: 600;
  float: right;
}

.project-approx-ammount {
  color: #98a5af;
  font-size: 14px;
  margin-top: -5px;
  float: right;
}
.project-post-date {
  color: #98a5af;
  font-size: 14px;
}

.project-description {
  color: #43525e;
  font-size: 14px;
}
.project-footer {
  background-color: #ffffff !important;
  padding: 5px 10px !important;
}
.project-status {
  background-color: rgba(10, 150, 32, 0.1);
  border-radius: 5px;
  width: 109px;
  height: 28px;
}

.project-status-text {
  text-align: center;
  color: #0a9620;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
}

.rows {
  margin: 0;
  padding: 0;
}
.amount-list {
  text-decoration: none;
  list-style: none;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.approx-amount-list {
  text-decoration: none;
  list-style: none;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}


@media (min-width: 1281px) {
  .dots-mobile {
    display: none;
  }

  .mobile-amount {
    display: none;
  }
  .mobile-approx-amount {
    display: none;
  }
}

@media only screen and (max-device-width: 992px) {
  /* .project-card {
    width: 385px;
  } */
  .rows {
    display: none;
  }
  .card-body-para {
    display: none;
  }

  .amount-container-desktop {
    display: none;
  }

  .project-status {
    float: right;
    margin-top: 10px;
  }

  /* .dots-mobile {
    margin-top: -60px;
    margin-top: -40px;
  } */

  .mobile-amount {
    /* margin-left: 20px; */
    margin-top: 12px;
  }
  .mobile-approx-amount {
    /* margin-top: 12px; */
    margin-top: 0px;
    margin-left: 20px;
  }
}

/* IPHONE 5/SE */
@media screen and (device-aspect-ratio: 40/71) {
  .mobile-amount {
    margin-left: 5px;
    margin-top: 12px;
  }
  .mobile-approx-amount {
    /* margin-top: 12px; */
    margin-top: 0px;
    margin-left: 0px;
  }
}

/* IPHONE 6/7/8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .project-card {
    /* width: 345px; */
    width: 100%;
  }
}

/* IPHONE 6/7/8 PLUS */
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .project-card {
    /* width: 385px; */
    width: 100%;
  }
}

/* IPHONE X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .project-card {
    /* width: 335px; */
    width: 100%;
  }
}

/* PIXEL 2 XL */
@media screen and (device-width: 411px) and (device-height: 823px) and (-webkit-device-pixel-ratio: 3.5) {
  .project-card {
    /* width: 385px; */
    width: 100%;
  }
}

/* SAMSUNG S5 */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
  .project-card {
    /* width: 335px; */
    width: 100%;
  }
}
