.opacity-1{
  opacity: 1 !important;
}
.wallet-select:hover{
background-color:#e8f4ff;
cursor: pointer;
/* color:#ffffff */
}
.wallet-selected{
  background-color:#0084ff;
  color:#ffffff;
  cursor: pointer;
}
.modal-custom{
  max-width: 400px !important;
}
.modal-heading {
  font-size: 18px;
  font-weight: 600;
  color: #43525e;
}
.dipute-modal-footer {
  font-weight: normal;
  font-size: 12px;
  color: #98a5af;
}

.dispute-send-ticket-btn {
  background: #0084ff !important;
  border-radius: 5px !important;
  color: white !important;
  border: none !important;
}
.dispute-cancel-btn {
  color: #eb5757 !important;
}
.dispute-labels {
  color: #43525e;
  font-size: 16px;
  font-weight: 600;
}
.dispute-upload-desc {
  font-size: 14px;
  color: #43525e;
  font-weight: 400;
}


.invoice-modal-heading {
  font-size: 20px;
  color: #43525e;
  font-weight: 600;
}
.invoice-modal-head-date {
  font-size: 16px;
  color: #98a5af;
  font-weight: 400;
}
.btn-complete-order {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff !important;
  background-color: #0084ff !important;
  box-shadow: 0px 8px 8px rgba(203, 203, 203, 0.25) !important;
  border-radius: 10px !important;
  height: 53px;
  width: 100%;
}
.invoice-modal-footer {
  justify-content: center !important;
}
.invoice-modal-footer-txt {
  font-size: 12px;
  text-align: center;
  color: #98a5af;
}
.invoice-item-left {
  float: right;
}
.invoice-tbl td,
.invoice-tbl th {
  border-top: none !important;
}
.btn-edit-profile-color {
  color: #0084ff !important;
  border: 1px solid #0084ff !important;
}
.btn-edit-profile {
  background: #ffffff;
  border-radius: 5px !important;
  width: 100%;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 53px !important;
}

.btn-continue-profile {
  height: 53px;
  background-color: #0084ff !important;
  border-radius: 5px !important;
  width: 100% !important;
  color: #fff !important;
}

/* ProfilePreview Modal CSS */

.img-profile {
  height: 47px;
  width: 47px;
  border-radius: 100px;
  float: left;
}

.profile-name {
  font-weight: normal;
  font-size: 12px;
  color: #43525e;
}

.profile-email {
  font-size: 11px;
  text-align: center;
  color: #98a5af;
  margin-top: -15px;
  /* margin-right: 35px; */
  float: left;
  margin-right: auto;
}
.profile-td-left {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #98a5af;
}
.profile-td-right {
  font-weight: 600;
  font-size: 14px;
  text-align: right;
  color: #43525e;
}
.profile-tbl td,
.profile-tbl th {
  border-top: none !important;
}

.edit-profile-modal-footer-text {
  font-size: 10px;
  color: #98a5af;
  text-align: center;
}
