.heading {
  font-size: 35px;
  font-weight: 600;
  color: #43525e;
}

.sub-text {
  font-size: 20px;
  font-weight: 400;
  color: #43525e;
}
.text-area {
  padding-top: 150px;
  padding-left: 30px !important;
}
.croudSourceImg {
  height: 409px;
  width: 523px;
}
/* @media (min-width: 1281px) {
} */

@media only screen and (max-device-width: 786px) {
  .croudSourceImg {
    position: relative;
    right:-20px;
  }
}

.note {
  font-size: 13px;
  color: #43525e;
  text-align: left;
}
