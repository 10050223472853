.breadcrumb-item + .breadcrumb-item::before {
  content: "»" !important;
}
.breadcrumb {
  background-color: #ffffff !important;
  border: 1px solid #e6e6e6 !important;
  border-radius: 5px !important;
}
.btn-proceed {
  color: #ffffff !important;
  background-color: #0084ff !important;
  border-radius: 10px !important;
  box-shadow: 0px 8px 8px rgba(203, 203, 203, 0.25) !important;
}
.profileBox {
  height: 85px;
}
#profile-heading {
  color: #43525e;
  font-weight: 600;
  font-size: 14px;
}
.profile-name {
  font-size: 14px;
  font-weight: 600;
  color: #43525e;
}

.profile-join-date {
  font-size: 10px;
  margin-top: -20px;
  font-weight: 500;
  color: #43525e;
}

.profile-total-projects {
  font-size: 10px;
  margin-top: -20px;
  font-weight: 500;
  color: #43525e;
}
