/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400&display=swap"); */
body {
  margin: 0;
  font-family: "Poppins" !important;
  font-style: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 80vh;
  /* background-color: #e5e5e5 !important; */
}

code {
  font-family: "Poppins" !important;
}

.default-font-family {
  font-family: "Poppins" !important;
}

.primary-font-color {
  color: #43525e !important;
}

.active-lnk {
  color: #0084ff !important;
  font-weight: 600;
}
.react-multi-carousel-track {
  margin: auto !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card-header {
  background-color: #ffff !important;
}
div.container-main {
  min-height: calc(100vh - 220px) !important;
}
.btn-offer {
  width: 218px;
  color: #ffff;
  border: none;
  height: 45px;
  background: #0084ff;
  box-shadow: 0px 8px 8px rgba(203, 203, 203, 0.25);
  border-radius: 10px;
}
.btn-buy {
  color: #0084ff !important;
  border: 1px solid #0084ff !important;
  margin-bottom: 20px;
  width: 25%;
  background-color: rgb(60, 120, 216, 0.2) !important;
  border-radius: 10px !important;
}
.btn-buy:hover {
  background-color: white !important;
}
.btn-buy-header:hover {
  background-color: white !important;
}
.page-item.active .page-link {
  background-color: #0084ff !important;
  border-color: #0084ff !important;
}
.pagination {
  align-items: center !important;
  justify-content: center !important;
}
.deliver-again-txt {
  font-weight: normal;
  font-size: 14px;
  color: #43525e;
}

.btn-closeproject {
  background-color: white;
  border: 1px solid #eb5757 !important;
  border-radius: 10px !important;
  color: #eb5757 !important;
}

.btn-closeproject:hover {
  background-color: #eb5757;
  border: 1px solid #eb5757 !important;
  color: white !important;
  border-radius: 10px !important;
}
.container-fluid{
  max-width:1366px !important;
}
.card{
  padding: 15px 5px;
}
.homeBg{
  z-index:-1;
  margin-left: -15px;
  bottom:60px;
  max-width: 100vw;
}
@media (max-width:767px){
.homeBg{
  content:url("/src/assests/images/HomeBgMobile.webp");
  top: 1120px;
  width: 100%;
}
}