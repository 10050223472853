.profilePic{
  width: 30px;
  height: 30px;
}
.noti-icon{
  height: 30px;
}

.noti-time{
  color: #98a5af
}
.noti-text{
  color: #43525e
}
/* .transformFixed{
  transform: translate(-630px, 50px) !important
} */

.notification-btn:hover, .notification-btn:focus, .noti:hover, .signUpBtnTxt:hover{
  background-color: #3c78d833 !important;
}
.noti{
  white-space: normal !important;
}
.noti-h{
  background-color: #e8f4ff !important;
}
.w-notification{
  max-height:50vh;
  width: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #0084ff #F5F5F5;
  scrollbar-width: thin;
  font-size: 14px !important;
}

/* custom colors  */
.w-notification::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
.w-notification::-webkit-scrollbar {
  background-color: #F5F5F5;
}

.w-notification::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #0084ff;
}
@media (max-width:390px){
  .meta-mask-mobile{
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (min-width: 1281px) {
  .navbar-nav {
    padding-left: 40px !important;
  }

  .meta-mask-mobile {
    display: none;
  }
  .amount-btn-mobile {
    display: none;
  }
}

@media only screen and (max-device-width: 540px) {
  .meta-mask {
    display: none !important;
  }
  .amount {
    display: none !important;
  }
}

/* IPAD PRO */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .meta-mask-mobile {
    display: none;
  }
  .amount-btn-mobile {
    display: none;
  }

  /* .navbar-nav {
    padding-left: 465px !important;
  } */
}

/* IPAD */

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .meta-mask-mobile {
    position: absolute;
    width: 151px;
    height: 30px;
    left: 450px !important;
    /* left: calc(50% - 251px / 2 + 494.5px);
        top: calc(50% - 50px / 2); */

    background: #ffffff;

    border: 1px solid #0084ff;
    box-sizing: border-box;
    border-radius: 100px;
  }
  .meta-mask {
    display: none !important;
  }
  .amount {
    display: none !important;
  }
}

/* IHPHONE 5 */
@media screen and (device-aspect-ratio: 40/71) {
  .meta-mask-mobile {
    left: 88px !important;
    /* left: calc(50% - 251px / 2 + 494.5px);
    top: calc(50% - 50px / 2); */
  }
}

.meta-mask {
  /* position: absolute; */
  display: inline-block;
  /* width: 251px; */
  width: auto;
  height: 50px;
  /* left: calc(50% - 390px / 2 + 494.5px);
  top: calc(50% - 50px / 2); */
  background: #ffffff;
  border: 1px solid #0084ff;
  box-sizing: border-box;
  border-radius: 100px;
}

.switchChain{
  text-decoration: underline;
}

.text-lightBlue{
  color: #0084ff !important;
}

.dropdown-toggle::after{
  display: none !important;
}

.metamaskIcon {
  width: 29px;
  height: 24px;
  margin-left: 15px;
  padding-right: 10px;
  margin-top: 11px;
  float: left;
}
.metaMaskBtnTxt {
  /* width: 161px; */
  height: 24px;
  margin-right: 26px;
  margin-top: 11px;
  float: right;
  color: #0084ff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.signUpBtnTxt {
  display: inline-block;
  background: #ffffff;
  border: 1px solid #0084ff !important;
  box-sizing: border-box;
  border-radius: 100px !important;
  padding:  12px;
  cursor: pointer;
  color: #0084ff !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.meta-mask-mobile {
  /* height: 50px; */
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #0084ff;
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 16px;
  color: #0084ff;
}

.metamaskIcon-mobile {
  width: 29px;
  height: 24px;
  margin-right: 16px;

  /* background: url(image.webp); */
}
.metaMaskBtnTxt-mobile {
  position: absolute;
  width: 161px;
  height: 24px;
  /* left: calc(50% - 61px / 2 + 518.5px);
    top: calc(50% - 24px / 2); */
  left: 35px;
  top: 2px;
  color: #0084ff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
}

.amount {
  width: 110px;
  /* height: 39px; */
  /* display: inline-block; */
  border: 1px solid #0084ff;
  box-sizing: border-box;
  filter: drop-shadow(0px 8px 8px rgba(203, 203, 203, 0.25));
  border-radius: 10px;
}

.zinIcon {
  /* margin-left: 10px;
  margin-top: 10px; */
  height: 16px;
  width: 16px;
  /* float: left; */
}

.amountBtnText {
  float: right;
  margin-top: 8px;
  margin-right: 15px;
  color: #0084ff;
  font-size: 14px;
}
.navbar {
  background-color: #ffffff !important;
  width: 100%;
}

.amount-btn-mobile {
  min-width: 110px;
  width: auto;
  height: 39px;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #0084ff;
  box-sizing: border-box;
  border-radius: 10px;
}
.amountIcon-mobile {
  width: 21px;
  height: 16px;
  margin-top: -2px;
}
.amountBtnTxt-mobile {
  margin:auto auto auto 12px;
  color: #0084ff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

/* Drawer Styling */

.ant-drawer-body {
  padding: 20px !important;
}
.drawer-connected-txt {
  text-align: center;
  margin-left: 10px;
  font-size: 12px;
  color: #43525e;
}
.drawer-profile-name {
  font-size: 13px;
  font-weight: normal;
  color: #43525e;
}
.drawer-profile-img {
  float: right;
  margin-top: -34px;
}
.drawer-profile-email {
  font-size: 11px;
  margin-top: -15px;
  color: #98a5af;
}
.drawer-lbl {
  float: left;
  font-weight: normal;
  font-size: 16px;
  color: #98a5af;
}
.drawer-amounts {
  float: right;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  color: #43525e;
}
.drawer-lbl-wallet {
  font-size: 14px;
  color: #98a5af;
}

.drawer-walletAddr {
  font-weight: normal;
  font-size: 12px;
  color: #43525e;
}

.postBtnHeader {
  background-color: #0084ff !important;
  color: #ffffff !important;
  width: auto !important;
  font-size: 14px !important;
  /* height: 40px !important; */
  font-weight: 600 !important;
  border-radius: 10px !important;
  margin-left: 10px;
}

